<template>
  <v-card>
    <v-card-title class="">
      <span class="me-3">({{ userList.length }}) Prospects</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-card-text>
      <b style="margin: 4px; padding: 4px; border: 1px solid grey; border-radius: 5px">
        <font-awesome-icon @click="copyAdMessageTitle()" icon="fa-solid fa-copy" class="ma-1"/>
        <b>Title:</b>
        <span contenteditable="true" id="advertising_message_title"> {{ advertising_message.title }}</span>
      </b>
      <p style="margin: 4px; padding: 4px; border: 1px solid grey; border-radius: 5px">
        <font-awesome-icon @click="copyAdMessageContent()" icon="fa-solid fa-copy" class="ma-1"/>
        <b>Message:</b> 
        <span contenteditable="true" id="advertising_message"> {{ advertising_message.content }}</span>
      </p>
      <p style="margin: 4px; padding: 4px; border: 1px solid grey; border-radius: 5px">
        <font-awesome-icon @click="copyAdMessageLink()" icon="fa-solid fa-copy" class="ma-1"/>
        <b>Link</b>
        <span contenteditable="true" id="advertising_message_link"> {{ advertising_message.link }}</span>
      </p>
    </v-card-text>

    <div class=" text-end">
      <v-btn
        v-if="$route.params.message_means === 'SMS'" 
        color="primary"
        class="me-3 mt-3"
        small
        @click.stop="getSendSMSDetails()"
      >
        <font-awesome-icon icon="fa-solid fa-share" class="pe-1" />
        Send SMS
      </v-btn>
    </div>

    <v-data-table
      v-if="$route.params.message_means === 'Whatsapp'" 
      :headers="headers"
      :items="userList"
      item-key="id"
      class="table-rounded"
      :items-per-page="20000"
      disable-sort wrap
      :search="search"
    >
      <!-- name -->
      <template #[`item.name`]="{item}">
        <div class="d-flex flex-column" style="cursor: pointer">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
        </div>
      </template>

      <template #[`item.id`]="{item}">
        <input type="checkbox" :id="'checkbox_'+item.id"/>
      </template>

      <template #[`item.phone_number`]="{item}">
        <a 
          v-if="$route.params.message_means === 'Whatsapp'" 
          @click="checkCheckBox(item.id)" 
          :href="'https://api.whatsapp.com/send?phone='+item.phone_number+'/&text=I salut you *'+item.name+'* 😃, i hope you are doing great 💫 today. %0a I\'m a PettyCash core team member, and am glad to bring you the following on our services.'" 
          target="_blank"
          >
            <v-btn class="primary" elevation="6" icon small>
              <font-awesome-icon icon="fa-solid fa-share-from-square"/>
            </v-btn>
        </a>
        <a v-else @click="checkCheckBox(item.id)">
          Still to Implement
        </a>
      </template>
    </v-data-table>

    <v-data-table
      v-if="$route.params.message_means === 'SMS'" 
      :headers="headers"
      :items="userList"
      item-key="id"
      class="table-rounded"
      :items-per-page="20000"
      disable-sort wrap
      :search="search"
    >

      <template #[`item.email`]="{item}">
        <input type="checkbox" :id="'select_checkbox_'+item.id"/>
      </template>
  
      <!-- name -->
      <template #[`item.name`]="{item}">
        <div class="d-flex flex-column" style="cursor: pointer">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
        </div>
      </template>

      <template #[`item.id`]="{item}" class="text--center">
        <input type="checkbox" :id="'checkbox_'+item.id"/>
      </template>

      <template #[`item.address`]="{item}" class="text--left">
        {{ item.phone_number }}
      </template>

      <template #[`item.phone_number`]="{item}">
        <a 
          @click.stop="getSendSMSDetails()"
          >
            <v-btn class="primary" elevation="6" icon small>
              <font-awesome-icon icon="fa-solid fa-share-from-square"/>
            </v-btn>
        </a>
      </template>
    </v-data-table>
    <v-dialog width="500px" v-model="setDialog"> 
      <v-card> 
        <v-card-title class=" white--text">Send SMS Messages</v-card-title> 
        <v-card-text class="black--text pt-2">
          <span v-html="SMSDetails"></span>
        </v-card-text> 
        <v-divider></v-divider> 
        <v-card-actions> 
          <v-btn color="success" text @click="setDialog = false" > Close </v-btn> 
          <v-spacer></v-spacer> 
          <v-btn color="success" @click="sendSMS()" > Send SMS </v-btn> 
        </v-card-actions> 
      </v-card> 
    </v-dialog> 
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'

export default {
  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Withdrawal: 'primary',
      Deposit: 'success',
      Cancelled: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      switch1: true,
      setDialog: false,
      headers: [
        { text: 'Select', value: 'email' },
        { text: 'Name', value: 'name' },
        { text: 'Phone Number', value: 'address' },
        { text: 'Message Sent', value: 'id' },
        { text: 'Send', value: 'phone_number' },
      ],
      userList: [],
      SMSDetails: '',
      advertising_message: [],
      advertising_message_title: '',
      advertising_message_content: '',
      advertising_message_link: '',
      statusColor,
      search: '',
      list: [],

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      this.getProspectsAdMessage();
      document.title = "Manager: Savings | Messaging"
  },

  methods:{
    checkCheckBox(item_id){
      const checkbox = document.getElementById("checkbox_"+item_id)
      checkbox.checked = true;
      this.$store.commit('setSnackBarMessage', this.$route.params.message_means+" message sent.")
      this.$store.commit('setSnackBarColor', "green darken-4")
      this.$store.commit('activateSnackBar', true)
    },
    
    copyAdMessageTitle(){
      const advertising_message_title = document.getElementById("advertising_message_title")
      navigator.clipboard.writeText(advertising_message_title.textContent)
      this.advertising_message_title = advertising_message_title.textContent
      this.$store.commit('setSnackBarMessage', "Message title successfully copied.")
      this.$store.commit('setSnackBarColor', "green darken-4")
      this.$store.commit('activateSnackBar', true)
    },

    copyAdMessageContent(){
      const advertising_message = document.getElementById("advertising_message")
      navigator.clipboard.writeText(advertising_message.textContent)
      this.advertising_message = advertising_message.textContent
      this.$store.commit('setSnackBarMessage', "Message content successfully copied.")
      this.$store.commit('setSnackBarColor', "green darken-4")
      this.$store.commit('activateSnackBar', true)
    },
   
    copyAdMessageLink(){
      const advertising_message_link = document.getElementById("advertising_message_link")
      navigator.clipboard.writeText(advertising_message_link.textContent)
      this.advertising_message_link = advertising_message_link.textContent
      this.$store.commit('setSnackBarMessage', "Message link successfully copied.")
      this.$store.commit('setSnackBarColor', "green darken-4")
      this.$store.commit('activateSnackBar', true)
    },

    async getProspectsAdMessage(){
      const group_id = this.$route.params.group_id  
      const message_id = this.$route.params.message_id  
      const message_means = this.$route.params.message_means  
      const start_date = this.$route.params.start_date  
      const end_date = this.$route.params.end_date  

      let formData = new FormData()
      formData.append('group_id', group_id)
      formData.append('message_id', message_id)
      formData.append('message_means', message_means)
      formData.append('start_date', start_date)
      formData.append('end_date', end_date)

      await axios
        .post('/api/v1/manager/get/prospects/ad_message/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(response => {
          this.userList = response.data["prospects"]  
          this.advertising_message = response.data["advertising_message"]  
          console.log(this.userList)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    getSendSMSDetails(){
      let number = 0
      this.setDialog=true
      this.list = []
      console.log(this.setDialog)

      for(let user in this.userList){
        console.log(String(this.userList[user].id))
        const checkbox = document.getElementById("select_checkbox_"+String(this.userList[user].id))
        if(checkbox.checked){
          number = number + 1
          this.list.push(this.userList[user].id)
        }
      }
      const advertising_message_title = document.getElementById("advertising_message_title")
      this.advertising_message_title = advertising_message_title.innerText
      const advertising_message_content = document.getElementById("advertising_message")
      this.advertising_message_content = advertising_message_content.innerText
      const advertising_message_link = document.getElementById("advertising_message_link")
      this.advertising_message_link = advertising_message_link.innerText

      this.SMSDetails = "You are about to send this title <b>'"+this.advertising_message_title+"'</b> and message <i>'"+this.advertising_message_content+"'</i> to <b>"+number+"</b> persons"
    },

    sendSMS(){
      console.log(this.list)
      let formData = new FormData()
      formData.append('messaging_list', this.list)
      formData.append('client_category', this.$route.params.group_id)
      formData.append('advertising_message_title', this.advertising_message_title)
      formData.append('advertising_message', this.advertising_message_content)
      formData.append('advertising_message_link', this.advertising_message_link)

      axios
        .post('/api/v1/manager/send/messages/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(response => {
          for (let item_position in this.list){
            const checkbox = document.getElementById("checkbox_"+String(this.list[item_position]))
            checkbox.checked = true
            const select_checkbox = document.getElementById("select_checkbox_"+String(this.list[item_position]))
            select_checkbox.checked = false
          }

          this.$store.commit('setSnackBarMessage', response.data[0].text)
          this.$store.commit('setSnackBarColor', "primary darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
          this.setDialog = false
        })
        .catch(error => {
          this.setDialog = false
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

  },
}
</script>
